import * as React from "react"
import styled from "styled-components"
import { css } from "styled-components"

import Send from "../images/send.svg"
import { Button } from "grommet";

const sharedStyle = css`
    font-size: 1rem;
    line-height: 1.5;
    background-image: linear-gradient(98deg, #2885d0, #2885d0 32px, #005da8 33px);
    padding: 8px 15px 8px 40px;
    border: 1px solid #e5eff6;
    font-weight: 400;
    color: #FFF;
    position: relative;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        background-image: linear-gradient(90deg, #2885d0, #005da8);

        svg {
        left: 12px;
        }
    }

    &:before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        transition: opacity 0.3s linear;
        opacity: 0;
    }
    &:after {
        content: "";
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: -10px;
    }

    svg {
        transition-duration: 0.3s;
        transition-property: left;
        width: 18px;
        height: 18px;
        position: absolute;
        left: 7px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
`;

const StyledButton = styled(Button)`
    ${sharedStyle}
`;

const styledBtn = ({children, ...props}) => {
    return (
        <StyledButton {...props}>
            <Send/>
            {children}
        </StyledButton>
    )
};

export default styledBtn;