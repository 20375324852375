import React, { useState, useEffect } from 'react';
import { Select } from 'grommet';

const getCountries = async () => {
    const data = await fetch("/ISO3166-1.alpha2.json");
    return await data.json();
}

const CountrySelect = ({ value, onChange }) => {
    const [countries, setCountries] = useState([]);
    const [searchText, setSearchText] = useState();

    useEffect(() => {
        const func = async () => {
            const response = await getCountries();
            const countryList = Object.keys(response).map(key => ({value: key, label: response[key]}));
            setCountries(countryList)
        };
        func();
    }, []);

    const filteredCountries =
        !searchText || searchText.length === 0 ?
            countries :
            countries.filter(country => country.label.toLowerCase().includes(searchText?.toLowerCase()) || country.value.toLowerCase().includes(searchText?.toLowerCase()));

    return (
        <Select
            options={filteredCountries}
            value={value}
            placeholder="Country"
            searchPlaceholder='Search for a country'
            labelKey="label"
            valueKey="value"
            onChange={({ option }) => onChange(option.value)}
            onSearch={(text) => setSearchText(text)}
        />
    );
};

export default CountrySelect;
