import React, { useState, useCallback, useReducer, useEffect } from "react"
import { Box, Text, Paragraph, Form, TextInput, TextArea, FormField, ResponsiveContext, Spinner, MaskedInput } from "grommet"

import Button from "../components/styled_button"
import Visit from "../images/visit.svg"
import Call from "../images/call_us.svg"
import Email from "../images/email.svg"
import World from "../images/world.svg"
import CountrySelect from "./contact/countrySelect"

const SEPERATOR = ", ";

const initialValues = {
  values: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    message: "",
    company: "",
    country: "",
    city: "",
    postalCode: ""
  },
  errors: {},
};

const betweenChecker = (value, min, max, required) => {
  let errors = [];

  if (required && !value)
    errors.push("Required");
  else if (min && value.length < min)
    errors.push(`Must be at least ${min} characters`);
  else if (max && value.length > max)
    errors.push(`Must be at most ${max} characters`);

  return errors.length > 0 ? errors : undefined;
}

const formReducer = (state, action) => {
  switch (action.type) {
    case 'firstName':
      return { ...state, values: { ...state.values, firstName: action.payload }, errors: { ...state.errors, firstName: betweenChecker(action.payload, 2, 50, true) } };
    case 'lastName':
      return { ...state, values: { ...state.values, lastName: action.payload }, errors: { ...state.errors, lastName: betweenChecker(action.payload, 2, 50, true) } };
    case 'emailAddress':
      return { ...state, values: { ...state.values, emailAddress: action.payload }, errors: { ...state.errors, emailAddress: betweenChecker(action.payload, 2, 100, true) } };
    case 'phoneNumber':
      return { ...state, values: { ...state.values, phoneNumber: action.payload }, errors: { ...state.errors, phoneNumber: betweenChecker(action.payload, 6, 50, true) } };
    case 'company':
      return { ...state, values: { ...state.values, company: action.payload }, errors: { ...state.errors, company: betweenChecker(action.payload, 2, 100, true) } };
    case 'country':
      return { ...state, values: { ...state.values, country: action.payload }, errors: { ...state.errors, country: betweenChecker(action.payload, 2, 50, true) } };
    case 'city':
      return { ...state, values: { ...state.values, city: action.payload }, errors: { ...state.errors, city: betweenChecker(action.payload, 2, 100, true) } };
    case 'postalCode':
      return { ...state, values: { ...state.values, postalCode: action.payload }, errors: { ...state.errors, postalCode: betweenChecker(action.payload, 2, 20, true) } };
    case 'message':
      return { ...state, values: { ...state.values, message: action.payload }, errors: { ...state.errors, message: betweenChecker(action.payload, 15, 1900, true) } };
    case 'reset':
      return initialValues;
    default:
      throw new Error(`Unknow action type: ${action.type}`);
  }
};

const initialMessage = {
  message: "",
  error: false,
  visible: false
};

const displayReducer = (_, action) => {
  switch (action.type) {
    case 'information':
      return { message: action.payload, error: false, visible: true };
    case 'error':
      return { message: action.payload, error: true, visible: true };
    case 'reset':
      return initialMessage;
    default:
      throw new Error(`Unknow action type: ${action.type}`);
  }
};

const ContactList = () => {
  const size = React.useContext(ResponsiveContext)

  const [state, dispatch] = useReducer(formReducer, initialValues);
  const [submitting, setSubmitting] = useState(false);
  const [displayMessage, displayMessageDispatch] = useReducer(displayReducer, initialMessage);

  const onSubmitForm = useCallback(async () => {
    if (submitting) return;

    if (Object.keys(state.values).length !== Object.keys(state.errors).length || Object.keys(state.errors).some(key => state.errors[key] !== undefined)) {
      displayMessageDispatch({ type: 'error', payload: 'Please fill out all required fields.' });
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        "Content-Type": "application/json"
      }),
      body: JSON.stringify(state.values),
      redirect: 'follow'
    };

    setSubmitting(true);

    await fetch('https://api.ecomate.cloud/contact/contactUs', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          dispatch({ type: 'reset' });
          displayMessageDispatch({ type: 'information', payload: 'Thank you for your message. We will get back to you as soon as possible.' });
        }
      })
      .catch(() => displayMessageDispatch({ type: 'error', payload: 'Something went wrong. Please try again later.' }));

    setSubmitting(false);

  }, [state, submitting]);

  useEffect(() => {
    if (displayMessage?.visible) {
      setTimeout(() => {
        displayMessageDispatch({ type: 'reset' });
      }, 15000);
    }
  }, [displayMessage]);

  const emailMask = [
    {
      regexp: /^[\w\-_.]+$/,
      placeholder: 'example',
    },
    { fixed: '@' },
    {
      regexp: /^[\w]+$/,
      placeholder: 'my',
    },
    { fixed: '.' },
    {
      regexp: /^[\w]+$/,
      placeholder: 'com',
    },
  ];

  const phoneMask = [
    {
      regexp: /^[ \d+-]+$/,
      placeholder: 'Phone'
    }
  ];

  return (
    <Box direction="row-responsive" responsive={true} margin={{ top: "medium", bottom: "medium" }}>
      <Box
        background="light-1"
        pad="large"
        basis="full"
        justify="center"
        margin={{
          top: size !== "small" ? "medium" : "none",
          bottom: size !== "small" ? "medium" : "none",
          left: size !== "small" ? "none" : "medium",
          right: size !== "small" ? "none" : "medium"
        }}
        round={{ size: "small", corner: size !== "small" ? "left" : "top" }}>
        <Form>
          <Box gap="small">
            <Box direction="row-responsive" gap="small">
              <ControlledField _key="firstName" placeholder="First Name" state={state} dispatch={dispatch} fill="horizontal" />
              <ControlledField _key="lastName" placeholder="Last Name" state={state} dispatch={dispatch} fill="horizontal" />
            </Box>

            <Box direction="row-responsive" gap="small">
              <FormField name="emailAddress" htmlFor="emailAddress" error={state.errors.emailAddress?.join(SEPERATOR)} basis="3/4">
                <MaskedInput mask={emailMask} id="emailAddress" name="emailAddress" value={state.values.emailAddress} onChange={(e) => dispatch({ type: "emailAddress", payload: e.target.value })} style={{ border: "1px solid #005da8", borderRadius: "0" }}/>
              </FormField>
              <FormField name="phoneNumber" htmlFor="phoneNumber" error={state.errors.phoneNumber?.join(SEPERATOR)} basis="1/4">
                <MaskedInput mask={phoneMask} id="phoneNumber" name="phoneNumber" value={state.values.phoneNumber} onChange={(e) => dispatch({ type: "phoneNumber", payload: e.target.value })} style={{ border: "1px solid #005da8", borderRadius: "0" }}/>
              </FormField>
            </Box>

            <ControlledField _key="company" placeholder="Company" state={state} dispatch={dispatch} />

            <Box direction="row-responsive" gap="small">
              <FormField name="country" htmlFor="country" basis="1/2" error={state.errors.country?.join(SEPERATOR)}>
                <CountrySelect value={state.values.country} onChange={(e) => dispatch({ type: 'country', payload: e })} />
              </FormField>
              <ControlledField _key="city" placeholder="City" state={state} dispatch={dispatch} basis="1/2" />
              <ControlledField _key="postalCode" placeholder="Postal Code" state={state} dispatch={dispatch} basis="1/4" />
            </Box>

            <FormField name="message" htmlFor="message" fill="vertical" error={state.errors.message?.join(SEPERATOR)}>
              <TextArea placeholder="Message" id="message" name="message" value={state.values.message} onChange={(e) => dispatch({ type: 'message', payload: e.target.value })} style={{ border: "1px solid #005da8", borderRadius: "0" }} fill />
            </FormField>

            <Box direction="row" gap="small" align="center">
              <Button style={{ alignSelf: "start" }} onClick={onSubmitForm}>
                {submitting ? <Spinner border={[{side: "all", size: "small", color: "transparent" }, {side: "horizontal", size: "small", color: "#FFF"}]} /> : "Send Message"}
              </Button>
              {displayMessage.visible && (<Text color={displayMessage.error ? "red" : "black"}>{displayMessage.message}</Text>)}
            </Box>

          </Box>
        </Form>
      </Box>
      <Box background="brand" align="center" justify="center" round="small" basis="1/2">
        <Box direction="column" gap="medium" pad="large">
          <Box direction="row" align="start" gap="medium">
            <Box pad={{ top: "small", bottom: "small" }}>
              <Visit height={40} width={32} />
            </Box>
            <Paragraph color="#E7E7E7">
              <Text weight={600} color="white">KROHNE Norway AS</Text>
              <br />
              Dept.: KROHNE Marine
              <br />
              Strømtangvegen 38
              <br />
              NO-3950 Brevik, Norway
            </Paragraph>
          </Box>
          <Box direction="row" align="start" gap="medium">
            <Box pad={{ top: "small", bottom: "small" }}>
              <Call height={40} width={32} />
            </Box>
            <Paragraph color="white">
              <Text weight={600}>Call us: </Text>
              <a rel="noreferrer" target="_blank" href="tel:+4735561220" style={{ color: "#E7E7E7" }}>
                +47 35 56 12 20
              </a>
            </Paragraph>
          </Box>
          <Box direction="row" align="start" gap="medium">
            <Box pad={{ top: "small", bottom: "small" }}>
              <Email height={40} width={32} />
            </Box>
            <Paragraph color="#E7E7E7">
              <Text weight={600} color="white">E-mail: </Text>
              <a rel="noreferrer" target="_blank" href="mailto:marine@krohne.com" style={{ color: "#E7E7E7" }}>
                marine@krohne.com
              </a>{" "}
              <br />
              <Text weight={600} color="white">Fax: </Text>
              <a rel="noreferrer" target="_blank" href="fax:+4735561221" style={{ color: "#E7E7E7" }}>
                +47 35 56 12 21
              </a>
            </Paragraph>
          </Box>
          <Box direction="row" align="start" gap="medium">
            <Box pad={{ top: "small", bottom: "small" }}>
              <World height={40} width={32} />
            </Box>
            <Paragraph>
              <Text weight={600} color="white">Website: </Text>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.krohne-marine.com"
                style={{ color: "#E7E7E7" }}
              >
                krohne-marine.com
              </a>
            </Paragraph>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const ControlledField = ({ _key, placeholder, state, dispatch, ...fields }) => (
  <FormField name={_key} htmlFor={_key} error={state.errors[_key]?.join(SEPERATOR)} {...fields}>
    <TextInput placeholder={placeholder} id={_key} name={_key} value={state.values[_key]} onChange={(e) => dispatch({ type: _key, payload: e.target.value })} />
  </FormField>
);

export default ContactList;
