import * as React from "react"

import styled from "styled-components"
import { Box, Heading, Main, Paragraph } from "grommet"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactList from "../components/contact_list"
import Container from "../components/container"
const StyledBox = styled(Box)`
  margin-top: 2em;
  height: 40vh;
  min-height: 30vw;
  background-size: cover;
`

const IndexPage = () => (
  <Layout>
    <Seo
      title="Contact Us"
      description="Contact details of KROHNE Marine, Headquartered in Brevik, Norway."
    />
    <Main className="body">
      <Container margin={{ top: "large", bottom: "large" }}>
        <Heading margin={{ bottom: "small" }}>Contact Us</Heading>
        <Paragraph>
          For sales, questions, service or more information about our products
          and solutions. We are happy to support you in any way.
        </Paragraph>
        <ContactList></ContactList>
        <Heading margin={{ top: "large" }} level={2}>
          KROHNE Marine
        </Heading>
        <Paragraph>
          The KROHNE Marine team, based in Brevik, Norway, is a dedicated expert
          division with over 60 years of experience in the marine industry.
          Since 1957, we have provided the global marine industry with certified
          systems, high quality products, measurement solutions, and services
          through long-term and close collaboration with our customers. Our
          systems are installed on a wide range of vessels, from small inland
          vessels to large seagoing vessels
        </Paragraph>
        <Paragraph>
          We offer our extensive in-house expertise by providing complete
          turn-key solutions with engineering, R&D, project management,
          commissioning, and training. Customers in all worldwide maritime hubs
          and shipbuilding countries benefit from our dedicated sales and
          service network.
        </Paragraph>
        <Paragraph>
          We recognize that the marine industry's top priority is to operate in
          a forward-thinking and environmentally friendly manner. As a result,
          KROHNE Marine is committed to meet environmental commitments and
          policy requirements. We take proactive measures by being ISO 9001 and
          ISO 14001 certified. As well as contributing to sustainable
          development through our products and solutions. For this reason, we
          created EcoMATE. A data-driven tool for vessel performance,
          optimization, emission monitoring and reporting.
        </Paragraph>
      </Container>
      <StyledBox background={`url(/km.webp)`} />
    </Main>
  </Layout>
)

export default IndexPage
